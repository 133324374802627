<template>
    <div id="respond" class="comment-respond">
        <h3 id="reply-title" class="comment-reply-title"><i class="fa fa-comment"></i> {{ leaveAReply }}</h3>
        <form action="#" method="post" class="comment-form" @submit.prevent="commentFormSubmit">
            <div class="form-group form-box" id="comment_block">
                <textarea class="summernote_textarea" v-model.trim="$v.formData.comment.$model" ></textarea>
                <p class="form-error" v-if="!formStatus && $v.formData.comment.$error && !$v.formData.comment.required">{{ validationRequired('comment.required') }}</p>
            </div>                          
            <p class="form-submit">
                <button type="submit" class="btn gradient-btn " :data-title="postComment">{{ postComment }}</button>
            </p>
            <div class="resMssg" v-if="formResponse" v-html="formResponse"></div>
        </form>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import Translation from '../../../public/translation.json'

export default {
    data() {
        return {
            formData: {
                comment: '',
                forum_topic_id: this.$route.params.id
            },
            formResponse: '',
            formStatus: true,
            defaultLang: localStorage.getItem('_store_lang') || 'en'
        }
    },
    computed:{
        leaveAReply(){
            return Translation.content[this.defaultLang]['leave_a_reply']
        },
        postComment() {
            return Translation.button[this.defaultLang]['post_comment']
        }
    },
    validations: {
        formData: {
            comment: {required}
        }
    },
    methods: {
        validationRequired(field){
            return Translation.validation[this.defaultLang][field]
        },
        commentFormSubmit(){
            this.formStatus = false
            this.$v.$touch()
            if(!this.$v.$invalid){    
                document.querySelector('.resMssg').style.display = 'block'
                this.formResponse = ''
                this.$http.post('account/user/add-comment', this.formData)
                    .then(res=>{            
                        if(res.status === 200){
                            this.resMsg = res.data.message;
                            this.formResponse =`<span class="alert alert-success">${res.data.message}</span>`  
                            this.resetForm()            
                        }
                        else {
                            this.formResponse =`<span class="alert alert-danger">${res.data.error}</span>`
                        }
                    }).catch(err=>{
                        if(err.response.status === 500)
                            this.formResponse =`<span class="alert alert-danger">Internal Server Error!</span>`
                        else
                            this.formResponse =`<span class="alert alert-danger">${err.response.data.error}</span>`
                    })
                this.togellingResponse()
            }
        },
        togellingResponse(){
            setTimeout(()=>{
                document.querySelector('.resMssg').style.display = 'none'
            }, 5000)
        },
        resetForm(){
            this.formData.comment = ''
            this.formStatus = true
        }
    }
}
</script>